var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-dark users-table modal-vue"},[_c('div',{staticClass:"d-flex justify-content-between align-items-start mt-4 mb-2 c-miners-sort pr-0"},[_c('div',{staticClass:"c-title pb-4"},[_vm._v(_vm._s(_vm.$t("Users")))]),_c('form',{staticClass:"c-miners-filtration d-flex"},[_c('CSelect',{attrs:{"options":['10', '50', '100'],"value":_vm.per_page},on:{"update:value":[function($event){_vm.per_page=$event},_vm.updateQuery]}})],1)]),(_vm.loadingUsers)?_c('p',{staticClass:"text-center"},[_c('CSpinner',{attrs:{"grow":"","size":"md"}})],1):_c('CDataTable',{staticClass:"bg-white rounded-md border-gray_secondary cursor-pointer",attrs:{"color":"main_black","items":_vm.users.data,"fields":_vm.fields,"sorter":""},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"rounded-circle d-inline-block imgBlcok"},[_c('CImg',{attrs:{"src":item.image
                ? _vm.hostUrl + item.image
                : require('@/assets/icons/users/profile_circle.png'),"alt":"","width":"32","height":"32","shape":"rounded-circle"}})],1)])]}},{key:"status",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"remove",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"d-flex justify-content-around h-100"},[_c('router-link',{attrs:{"to":("/profile/" + (item.id))}},[_c('img',{staticClass:"cursor-pointer m-0 p-0 btn",attrs:{"src":require("@/assets/icons/users/edit_icon.svg")}})]),_c('img',{staticClass:"btn m-0 p-0",attrs:{"src":require("@/assets/icons/users/remove_icon.svg")},on:{"click":function($event){return _vm.deleteUser(item)}}})],1)]}}])}),_c('CPagination',{attrs:{"activePage":_vm.page,"pages":_vm.users.meta.last_page,"doubleArrows":false,"align":"center"},on:{"update:activePage":[function($event){_vm.page=$event},_vm.updatePage],"update:active-page":function($event){_vm.page=$event}}}),_c('CModal',{staticClass:"text-main_black bg-milk_mix",attrs:{"title":"Edit user","color":"lightGray","show":_vm.warningModal},on:{"update:show":function($event){_vm.warningModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
                var item = ref.item;
return [_c('button',{staticClass:"btn btn-lightGray",attrs:{"type":"button"},on:{"click":function () { return _vm.updateUser(); }}},[_vm._v(" OK ")])]}}])},[(!_vm.modalInfo.curUser)?_c('CSpinner',{attrs:{"grow":"","size":"md"}}):_c('CForm',{on:{"submit":_vm.updateUser}},[_c('p',{staticClass:"border-0 mb-2"},[_c('label',{attrs:{"for":"first_name"}},[_vm._v("First Name")]),_c('CInput',{attrs:{"id":"first_name","name":"first_name","value":_vm.modalInfo.curUser.first_name},on:{"change":_vm.updateValues}})],1),_c('p',{staticClass:"border-0"},[_c('label',{attrs:{"for":"last_name"}},[_vm._v("Last Name")]),_c('CInput',{attrs:{"id":"last_name","name":"last_name","value":_vm.modalInfo.curUser.last_name},on:{"change":_vm.updateValues}})],1),_c('p',{staticClass:"border-0"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('CInput',{attrs:{"id":"email","name":"email","value":_vm.modalInfo.curUser.user.email},on:{"change":_vm.updateValues}})],1),_c('p',{staticClass:"border-0"},[_c('label',{attrs:{"for":"mobile"}},[_vm._v("Mobile")]),_c('CInput',{attrs:{"value":_vm.modalInfo.curUser.phone,"id":"mobile","name":"phone"},on:{"change":_vm.updateValues}})],1),_c('div',[_c('label',{attrs:{"for":"type"}},[_vm._v("Type")]),_c('div',{staticClass:"bg-white text-main_black border-gray_input rounded-md overflow-hidden px-3 py-2"},[_c('CCard',{staticClass:"m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer",attrs:{"color":"white border-0"},on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[_c('span',[_vm._v(_vm._s(_vm.modalInfo.curUser.role))]),_c('img',{attrs:{"src":require("@/assets/icons/users/arrow_bottom.svg"),"alt":"","width":"10px "}})]),_c('CCollapse',{staticClass:"border-0 p-0",attrs:{"show":_vm.collapse}},[_c('CCard',{staticClass:"border-0 p-0 mt-2 mb-0 radio_card"},[_c('CInputRadioGroup',{staticClass:"text-gray",attrs:{"options":[
                  { value: 'admin', label: 'Admin' },
                  { value: 'operator', label: 'Operator' },
                  {
                    value: 'customer',
                    label: 'Customer',
                    props: { desription: 'description text' },
                  } ],"checked":_vm.modalInfo.curUser.user.role,"name":"role"},on:{"update:checked":_vm.updateValues}})],1)],1)],1)]),_c('div',{staticClass:"mt-4"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Status")]),_c('div',{staticClass:"bg-white text-main_black border-gray_input rounded-md overflow-hidden px-3 py-2"},[_c('CCard',{staticClass:"m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer",attrs:{"color":"white border-0"},on:{"click":function($event){_vm.modalInfo.collapse = !_vm.modalInfo.collapse}}},[_c('span',[_vm._v(_vm._s(_vm.modalInfo.curUser.status))]),_c('img',{attrs:{"src":require("@/assets/icons/users/arrow_bottom.svg"),"alt":"","width":"10px "}})]),_c('CCollapse',{staticClass:"border-0 p-0",attrs:{"show":_vm.modalInfo.collapse}},[_c('CCard',{staticClass:"border-0 p-0 mt-2 mb-0 radio_card"},[_c('CInputRadioGroup',{staticClass:"text-gray",attrs:{"options":_vm.modalInfo.radioGroups,"checked":_vm.modalInfo.curUser.user.status,"name":"status"},on:{"update:checked":_vm.updateValues}})],1)],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }