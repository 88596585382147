<template>
  <div class="text-dark users-table modal-vue">
    <div
      class="d-flex justify-content-between align-items-start mt-4 mb-2 c-miners-sort pr-0"
    >
      <div class="c-title pb-4">{{ $t("Users") }}</div>
      <form class="c-miners-filtration d-flex">
        <CSelect :options="['10', '50', '100']" :value.sync="per_page" @update:value="updateQuery" />
      </form>
    </div>
    <p class="text-center" v-if="loadingUsers">
      <CSpinner grow size="md" />
    </p>
    <CDataTable
      color="main_black"
      :items="users.data"
      v-else
      :fields="fields"
      class="bg-white rounded-md border-gray_secondary cursor-pointer"
      sorter
    >
      <template #image="{ item }">
        <td>
          <div class="rounded-circle d-inline-block imgBlcok">
            <CImg
              :src="
                item.image
                  ? hostUrl + item.image
                  : require('@/assets/icons/users/profile_circle.png')
              "
              alt=""
              width="32"
              height="32"
              shape="rounded-circle"
            />
          </div>
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <CBadge :color="getBadge(item.status)">
            {{ item.status }}
          </CBadge>
        </td>
      </template>

      <template #remove="{ item }">
        <td class="d-flex justify-content-around h-100">
          <router-link :to="`/profile/${item.id}`">
            <img
              src="@/assets/icons/users/edit_icon.svg"
              class="cursor-pointer m-0 p-0 btn"
            />
          </router-link>
          <img
            src="@/assets/icons/users/remove_icon.svg"
            class="btn m-0 p-0"
            @click="deleteUser(item)"
          />
        </td>
      </template>
    </CDataTable>
    <CPagination :activePage.sync="page" :pages="users.meta.last_page"  @update:activePage="updatePage" :doubleArrows="false" align="center" />
    <CModal
      title="Edit user"
      color="lightGray"
      class="text-main_black bg-milk_mix"
      :show.sync="warningModal"
    >
      <CSpinner grow size="md" v-if="!modalInfo.curUser" />
      <CForm v-else @submit="updateUser">
        <p class="border-0 mb-2">
          <label for="first_name">First Name</label>
          <CInput
            v-on:change="updateValues"
            id="first_name"
            name="first_name"
            :value="modalInfo.curUser.first_name"
          >
          </CInput>
        </p>
        <p class="border-0">
          <label for="last_name">Last Name</label>
          <CInput
            id="last_name"
            v-on:change="updateValues"
            name="last_name"
            :value="modalInfo.curUser.last_name"
          >
          </CInput>
        </p>
        <p class="border-0">
          <label for="email">Email</label>
          <CInput
            id="email"
            name="email"
            v-on:change="updateValues"
            :value="modalInfo.curUser.user.email"
          >
          </CInput>
        </p>
        <p class="border-0">
          <label for="mobile">Mobile</label>
          <CInput
            :value="modalInfo.curUser.phone"
            id="mobile"
            name="phone"
            v-on:change="updateValues"
          >
          </CInput>
        </p>
        <div>
          <label for="type">Type</label>
          <div
            class="bg-white text-main_black border-gray_input rounded-md overflow-hidden px-3 py-2"
          >
            <CCard
              @click="collapse = !collapse"
              color="white border-0"
              class="m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span>{{ modalInfo.curUser.role }}</span>
              <img src="@/assets/icons/users/arrow_bottom.svg" alt="" width="10px " />
            </CCard>
            <CCollapse :show="collapse" class="border-0 p-0">
              <CCard class="border-0 p-0 mt-2 mb-0 radio_card">
                <CInputRadioGroup
                  :options="[
                    { value: 'admin', label: 'Admin' },
                    { value: 'operator', label: 'Operator' },
                    {
                      value: 'customer',
                      label: 'Customer',
                      props: { desription: 'description text' },
                    },
                  ]"
                  class="text-gray"
                  :checked="modalInfo.curUser.user.role"
                  name="role"
                  v-on:update:checked="updateValues"
                >
                </CInputRadioGroup>
              </CCard>
            </CCollapse>
          </div>
        </div>
        <div class="mt-4">
          <label for="type">Status</label>
          <div
            class="bg-white text-main_black border-gray_input rounded-md overflow-hidden px-3 py-2"
          >
            <CCard
              @click="modalInfo.collapse = !modalInfo.collapse"
              color="white border-0"
              class="m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span>{{ modalInfo.curUser.status }}</span>
              <img src="@/assets/icons/users/arrow_bottom.svg" alt="" width="10px " />
            </CCard>
            <CCollapse :show="modalInfo.collapse" class="border-0 p-0">
              <CCard class="border-0 p-0 mt-2 mb-0 radio_card">
                <CInputRadioGroup
                  :options="modalInfo.radioGroups"
                  class="text-gray"
                  :checked="modalInfo.curUser.user.status"
                  name="status"
                  v-on:update:checked="updateValues"
                >
                </CInputRadioGroup>
              </CCard>
            </CCollapse>
          </div>
        </div>
      </CForm>
      <template #footer="{ item }">
        <button type="button" class="btn btn-lightGray" @click="() => updateUser()">
          OK
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import i18n from "../../i18n";
import request from "../../plugins/request";
const fields = [
  {
    key: "image",
    filter: false,
    sorter: false,
    _style: "min-width:100px;",
  },
  {
    key: "email",
    filter: false,
    sorter: false,
    _style: "min-width:100px;",
  },
  {
    key: "first_name",
    filter: false,
    sorter: false,
    _style: "min-width:100px;",
  },
  {
    key: "last_name",
    filter: false,
    sorter: false,
    _style: "min-width:100px;",
  },
  { key: "full_name", filter: false, sorter: false, _style: "min-width:100px" },
  // { key: 'registered', filter: false, label: "", _style: 'min-width:100px;'},
  { key: "phone", filter: false, sorter: false, _style: "min-width:100px;" },
  { key: "role", filter: false, sorter: false, _style: "min-width:100px;" },
  { key: "status", _style: "min-width:100px;", filter: false, sorter: false },
  {
    key: "remove",
    filter: false,
    label: "",
    _style: "width: 10%;",
  },
];
export default {
  name: "Users",
  props: ["state"],
  components: {},
  data() {
    return {
      items: [],
      fields,
      i18n,
      warningModal: false,
      details: [],
      collapse: true,
      loadingUsers: true,
      collapseDuration: 0,
      page: this.$route.params.id ? parseInt(this.$route.params.id) : 1,
      per_page: this.$route.query.per_page ? this.$route.query.per_page : 10,
      hostUrl: process.env.VUE_APP_API,
      modalInfo: {
        curUser: null,
        choosenStatus: "",
        loading: true,
        radioGroups: [
          { value: "active", label: "Active" },
          { value: "blocked", label: "Blocked" },
          {
            value: "password_blocked",
            label: "Password blocked",
            props: { desription: "description text" },
          },
        ],
        collapse: true,
      },
    };
  },
  created() {
    this.fetchUsers();
    this.curPage();
  },
  computed: {
    ...mapState("user", ['users']),
  },
  watch: {
    "$store.state.miners.userSearchText": {
      handler: function (e) {
        this.searchUsers(e);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('user', ['GET_USERS','SEARCH_USER']),
    updatePage(e) {
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          id: e,
        },
      });
    },
    updateQuery(e){
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          id: e,
        },
        query: {
          ...this.$route.query,
          per_page: e
        }
      });
    },
    curPage() {
      this.$emit("curPage", "user");
      this.$emit("curUsers", this.fetchUsers);
    },
    searchUsers(username) {
      this.SEARCH_USER({page: this.page, body: {...this.$route.query, userName: username}}).then(res => {
        // console.log(res);
      })
      // request.get("/api/users/searchFor?userName=" + username).then((res) => {
      //   res = res.data.users.map((obj) => {
      //     const new_res = { ...obj, ...obj.user };
      //     delete new_res["user"];
      //     return new_res;
      //   });
      //   this.items = res;
      //   this.loadingUsers = false;
      // });
    },
    fetchUsers() {
      this.GET_USERS({page: this.page, body: this.$route.query}).then(() =>{
        this.loadingUsers = false;
      })
    },
    getBadge(status) {
      switch (status) {
        case "active":
          return "success";
        case "password_blocked":
          return "secondary";
        case "blocked":
          return "danger";
        default:
          "primary";
      }
    },
    getDetailUser(item) {
      this.warningModal = !this.warningModal;
      request.get(`/api/v1/users/${item.id}`).then((res) => {
        this.modalInfo.curUser = res.data.user;
      });
    },
    updateUser() {
      request
        .put(`/api/v1/users/${this.modalInfo.curUser.id}`, this.modalInfo.curUser)
        .then(() => {
          this.warningModal = false;
          this.fetchUsers();
        });
    },
    updateValues(value, e) {
      this.modalInfo.curUser = {
        ...this.modalInfo.curUser,
        [e.target.name]: value,
      };
    },
    deleteUser(item) {
      this.loadingUsers = true;
      request.delete(`/api/v1/users/${item.id}`).then(() => {
        this.fetchUsers();
      });
    },
  },
};
</script>

<style scoped lang="sass"></style>
